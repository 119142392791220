// This can be directly added to any of your `.ts` files like `router.ts`
// It can also be added to a `.d.ts` file. Make sure it's included in
// project's tsconfig.json "files"
import "vue-router"

import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouteRecordRaw
} from "vue-router"

// import providerRoutes from './protected/providers';
import protectedRoutes from "./protected"
import { useAuthStore } from "@/store/modules/auth/authStore"
import { useBreadcrumbStore } from "@/store/modules/user/useBreadcrumbStore"

// To ensure it is treated as a module, add at least one `export` statement
export {}

export interface IntNavigation {
  title: string
  translationKey?: string
  routeName?: string
}
declare module "vue-router" {
  interface RouteMeta {
    // must be declared by every route
    isProtected?: boolean
    navigation?: IntNavigation[]
    variation?: string
  }
}

const routes: RouteRecordRaw[] = [
  ...protectedRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "pageNotFound",
    component: () => import("@/modules/error/PageNotFound.vue"),
    meta: {
      isProtected: true,
      variation: "comingSoon",
      navigation: [
        {
          title: "Home",
          translationKey: "common.home",
          routeName: "home"
        },
        {
          title: "Page not found",
          translationKey: "error.pageNotFound",
          routeName: ""
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
  scrollBehavior() {
    // always scroll top
    return { top: 0 }
  }
})

// Interceptor for checking user is authenticated or not for protected pages
router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const authStore = useAuthStore()
    const breadcrumbStore = useBreadcrumbStore()
    breadcrumbStore.setPageBreadcrumbs(
      to.meta.navigation !== undefined && to.meta.navigation.length > 0 ? to.meta.navigation : []
    )
    if (to.meta.isProtected ?? false) {
      try {
        await authStore.checkToken()
        next()
      } catch {
        console.error("Error in token")
        await authStore.logout()
      }
    } else {
      next()
    }
  }
)

export default router
