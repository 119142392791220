import { computed, reactive } from "vue"
import { defineStore } from "pinia"

export interface ILoadingState {
  preventLoading: boolean
  loading: string[]
}

export const useLoadingStore = defineStore("loadingStore", () => {
  const state = reactive<ILoadingState>({
    preventLoading: false,
    loading: []
  })

  const loadingState = computed(() => state.loading.length > 0)

  const preventLoading = computed(() => state.preventLoading)

  function incrementLoadingState(loading: string) {
    state.loading.push(loading)
  }

  function decrementLoadingState() {
    state.loading.pop()
  }

  function setPreventLoading(value: boolean) {
    state.preventLoading = value
  }

  return {
    incrementLoadingState,
    decrementLoadingState,
    setPreventLoading,
    loadingState,
    preventLoading
  }
})
