import { useAuthStore } from "@/store/modules/auth/authStore"
import Keycloak from "keycloak-js"
import getEnv from "../util/env"

const KEYCLOAK_URL = getEnv("VITE_APP_KEYCLOAK_URL")
const KEYCLOAK_CLIENT_ID = getEnv("VITE_APP_KEYCLOAK_CLIENT_ID")
const DEFAULT_KEYCLOAK_REALM = getEnv("VITE_APP_DEFAULT_KEYCLOAK_REALM")
const MINIMAL_TOKEN_VALIDITY = 10 // min token validity in seconds
// const TOKEN_EXPIRY_SECONDS = 300;
const SESSION_EXPIRY_SECONDS = 2700

const keycloakConfig: Keycloak.KeycloakConfig = {
  url: KEYCLOAK_URL,
  realm: DEFAULT_KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
}

const KC = new Keycloak({
  url: KEYCLOAK_URL,
  realm: DEFAULT_KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
})

const doLogin = KC.login

const doLogout = KC.logout

const getToken = () => KC.token

const getParsedToken = () => KC.tokenParsed

const updateToken = (successCallback?: () => void) =>
  KC.updateToken(MINIMAL_TOKEN_VALIDITY).then(successCallback).catch(doLogin)

const getUsername = () => KC.tokenParsed?.preferred_username

const getName = () => KC.tokenParsed?.name

const getEmail = () => KC.tokenParsed?.email

const getCompany = () => KC.tokenParsed?.organisation

const getBpn = () => KC.tokenParsed?.bpn

const getTenant = () => KC.tokenParsed?.tenant

const hasRole = (roles: string[]) => roles.some((role: string) => KC.hasRealmRole(role))

const isLoggedIn = () => !(KC.token == null)

// eslint-disable-next-line no-prototype-builtins
const hasValidResource = () =>
  KC.tokenParsed?.resource_access?.hasOwnProperty(keycloakConfig.clientId)

const getLoggedUser = () => ({
  userName: getUsername(),
  name: getName(),
  email: getEmail(),
  company: getCompany(),
  bpn: getBpn(),
  tenant: getTenant(),
  token: getToken(),
  parsedToken: getParsedToken()
})

const getNewSessionExpiryTime = () => {
  return Date.now() + SESSION_EXPIRY_SECONDS * 1000
}

const update = () => {
  KC.updateToken(MINIMAL_TOKEN_VALIDITY)
    .then((refreshed: boolean) => {
      const authStore = useAuthStore()
      if (refreshed) console.log(`${getUsername()} token refreshed ${refreshed}`)
      authStore.setAuthorized(getToken())
    })
    .catch(() => {
      console.log(`${getUsername()} token refresh failed`)
    })
}

const initKeycloak = () => {
  return KC.init({
    onLoad: "login-required",
    pkceMethod: "S256"
  })
}

KC.onTokenExpired = () => {
  console.log(`${getUsername()} token expired`)
  update()
}

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getParsedToken,
  updateToken,
  getUsername,
  getEmail,
  hasRole,
  getLoggedUser,
  hasValidResource,
  getNewSessionExpiryTime
}

export default AuthService
