import { computed, reactive } from "vue"
import { defineStore } from "pinia"
import axios from "axios"
import AuthService from "@/common/services/AuthServices"

export interface IAuthState {
  token: string | undefined
  sessionExpiryTime?: number
}

export const useAuthStore = defineStore("authStore", () => {
  const state = reactive<IAuthState>({
    token: undefined,
    sessionExpiryTime: AuthService.getNewSessionExpiryTime()
  })

  const authState = computed(() => {
    return state
  })

  function login() {
    return AuthService.doLogin()
  }

  async function logout() {
    state.token = undefined
    if (state.token !== undefined) {
      axios.defaults.headers.common.Authorization = null
    }
    await AuthService.doLogout()
  }

  async function checkToken() {
    if (state.token !== undefined) {
      // Resolve if there's a token already and it's not a refresh request
      return 1
    } else {
      // Initialize token if it's not present yet
      await initToken()
      return 1
    }
  }

  async function initToken() {
    try {
      await AuthService.initKeycloak()
      const authToken = AuthService.getToken()
      setAuthorized(authToken)
    } catch (error) {
      await login()
      throw error
    }
  }

  function setAuthorized(value: string | undefined) {
    state.token = value
    if (state.token !== undefined) {
      axios.defaults.headers.common.Authorization = `Bearer ${state.token}`
    }
  }

  function updateSessionExpiryTime() {
    state.sessionExpiryTime = AuthService.getNewSessionExpiryTime()
  }

  async function refreshToken() {
    await AuthService.updateToken(() => {
      console.log("update")
      setAuthorized(AuthService.getToken())
    })
  }

  const isAuthorized = computed(() => state.token !== null)

  const token = computed(() => state.token)

  return {
    setAuthorized,
    initToken,
    checkToken,
    login,
    logout,
    authState,
    updateSessionExpiryTime,
    isAuthorized,
    token,
    refreshToken
  }
})
