import type { RouteRecordRaw } from "vue-router"
import { ProtectedRoutes } from "../IRoute"

const consumerRoutes: RouteRecordRaw[] = [
  {
    path: "/search",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: ProtectedRoutes.search.offers.path,
        name: ProtectedRoutes.search.offers.pathName,
        component: () => import("@/modules/consume/search-offer/pages/SearchOffer.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Consume",
              translationKey: "sideMenu.consume",
              routeName: ""
            },
            {
              title: "Browse data offer",
              translationKey: "sideMenu.searchDataOffers",
              routeName: ProtectedRoutes.search.offers.pathName
            }
          ]
        }
      }
    ]
  }
]

export default consumerRoutes
