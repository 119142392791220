export default function getEnv(name: string): string {
  const result: string = (window as any)?.config?.[name] || import.meta.env[name]
  return result
}
export enum APP_VERSION_TYPE {
  CX = 'CX',
  NON_CX = 'NON-CX'
}
export function isCxApp () {
  return getEnv('VITE_APP_CI_VERSION_TYPE') === APP_VERSION_TYPE.CX;
}

export function featureAllowedList () {
  const productType = getEnv('VITE_APP_CI_VERSION_TYPE') || APP_VERSION_TYPE.CX
  let features: string | string[] = getEnv('VITE_APP_FEATURES')
  if(typeof features === 'string') {
    features = features.split(',')
  }

  return {
    productType,
    isCxProduct: isCxApp(), 
    isPrepareAllowed: features.includes('prepare'),
    isDataMappingAllowed: features.includes('crossrelations'),
    isDataConverterAllowed: features.includes('converters'),
    isDataExchangeAllowed: features.includes('data-exchange-log')
  }
}
