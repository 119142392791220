<template>
  <div class="notification-bar">
    <TNotificationBar v-model="notifications">
      <TNotification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      >
        {{
          notification.variables
            ? $t(notification.text, notification.variables)
            : $t(notification.text)
        }}
      </TNotification>
    </TNotificationBar>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import TNotification from "../notifications/TNotification/TNotification.vue"
import TNotificationBar from "../notifications/TNotificationBar/TNotificationBar.vue"

import { useNotificationStore } from "./store/useNotification"

const notificationStore = useNotificationStore()

const notifications = computed({
  get() {
    return notificationStore.notifications
  },
  set(value) {
    notificationStore.notifications = value
  }
})
</script>
