import axios from "axios"
import { useLoadingStore } from "@/store/modules/loading/loadingStore"

axios.interceptors.request.use(
  (config) => {
    const loadingStore = useLoadingStore()
    if (!loadingStore.preventLoading) {
      loadingStore.incrementLoadingState(Date.now() as unknown as string)
    }

    return config
  },
  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => {
    const loadingStore = useLoadingStore()
    if (!loadingStore.preventLoading) {
      loadingStore.decrementLoadingState()
    }
    loadingStore.setPreventLoading(false)
    return response
  },
  (error) => {
    const loadingStore = useLoadingStore()
    if (!loadingStore.preventLoading) {
      loadingStore.decrementLoadingState()
    }
    loadingStore.setPreventLoading(false)
    return Promise.reject(error)
  }
)
