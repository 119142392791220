import { RouteRecordRaw } from "vue-router"
import { ProtectedRoutes } from "../IRoute"

const governRoutes: RouteRecordRaw[] = [
  {
    path: "/govern",
    name: "govern",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: ProtectedRoutes.govern.policy.path,
        name: ProtectedRoutes.govern.policy.pathName,
        component: () => import("@/modules/govern/non-cx/policies/pages/Policies.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "My Policies",
              translationKey: "sideMenu.myPolicies",
              routeName: ""
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.govern.subscribedAgreements.path,
        name: ProtectedRoutes.govern.subscribedAgreements.pathName,
        component: () =>
          import("@/modules/govern/non-cx/subscribedAgreements/SubscribedAgreements.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Subscribed data agreements",
              translationKey: "sideMenu.consumerContracts",
              routeName: ""
            }
          ]
        }
      }
    ]
  }
]

export default governRoutes
