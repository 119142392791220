import getEnv from "@/common/util/env"
import i18n from "@/i18n"

export type Locale = "en" | "de" | "es" | "fr"

const Translation = {
  get defaultLocale(): Locale {
    return getEnv("VITE_APP_DEFAULT_LOCALE") as Locale
  },

  isLocaleSupported(locale: string): boolean {
    return Translation.supportedLocales.includes(locale)
  },

  getUserLocale(): Locale {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const locale =
      Translation.getPersistedLocale() ?? window.navigator.language ?? Translation.defaultLocale

    return locale.split("-")[0] as Locale
  },

  getPersistedLocale(): Locale | null {
    const persistedLocale: string | null = localStorage.getItem("user-locale")
    if (typeof persistedLocale === "string" && Translation.isLocaleSupported(persistedLocale)) {
      return persistedLocale as Locale
    } else {
      return null
    }
  },

  guessDefaultLocale(): Locale {
    const userPersistedLocale = Translation.getPersistedLocale()

    if (userPersistedLocale !== null) {
      return userPersistedLocale as Locale
    }

    const userPreferredLocale = Translation.getUserLocale()

    if (Translation.isLocaleSupported(userPreferredLocale)) {
      return userPreferredLocale as Locale
    }

    return Translation.defaultLocale as Locale
  },

  get supportedLocales(): string[] {
    return getEnv("VITE_APP_SUPPORTED_LOCALES").split(",")
  },

  get currentLocale(): Locale {
    return i18n.global.locale.value
  },

  set currentLocale(newLocale: Locale) {
    i18n.global.locale.value = newLocale
  },

  switchLanguage(newLocale: Locale) {
    Translation.currentLocale = newLocale
    document.querySelector("html")!.setAttribute("lang", newLocale)
    localStorage.setItem("user-locale", newLocale)
  }
}

export default Translation
