import { createI18n } from "vue-i18n"

import en from "./locales/en.json"
// import de from "./locales/de.json"
// import es from "./locales/es.json"
// import fr from "./locales/fr.json"
// import getEnv from "@/common/util/env"

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  legacy: false,
  availableLocales: "en",
  messages: {
    en
  }
})

export default i18n
