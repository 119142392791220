import type { ObjectDirective } from "vue"

interface IOuterClickHTMLElement extends HTMLElement {
  event?: EventListener
}

export const OuterClick: ObjectDirective<IOuterClickHTMLElement, any> = {
  beforeMount(el: IOuterClickHTMLElement, binding: any) {
    const onOuterClickFn = binding.value
    if (onOuterClickFn instanceof Function) {
      const handler: EventListener = (event: Event) => {
        if (event.target instanceof Node && !el.contains(event.target)) {
          onOuterClickFn()
        }
      }
      el.event = handler
      document.addEventListener("click", handler, true)
    }
  },
  unmounted(el: IOuterClickHTMLElement) {
    if (el.event != null) {
      document.removeEventListener("click", el.event, true)
    }
  }
}

export const textTransform = {
  mounted: (el: HTMLElement) => {
    const { innerText } = el
    el.innerHTML = `${innerText.charAt(0).toUpperCase()}${innerText.toLowerCase().slice(1)}`
  }
}
