<template>
  <div class="app">
    <LoadingBar />
    <NotificationBar />
    <router-view />
    <SvgSprite />
  </div>
</template>

<script lang="ts" setup>
import NotificationBar from "@/components/NotificationBar/NotificationBar.vue"
import LoadingBar from "@/components/LoadingBar/LoadingBar.vue"
import SvgSprite from "./components/AppSvgIconsSprite/AppSvgIconsSprite.vue"
import i18n from "@/i18n"
import Translation from "./i18n/translation"

function setDefaultLocale() {
  i18n.global.locale.value = Translation.guessDefaultLocale()
}

setDefaultLocale()
</script>

<style lang="scss" scoped>
@import "./styles/utilities/variables";

// For sticky footer
.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: var(--telekom-color-background-canvas-subtle);
}

.view {
  // Sticky footer
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: $t-sidebar-width;

  &.collapse {
    padding-left: $t-sidebar-width-collapse;
  }
}
:root {
  color-scheme: light only;
}
</style>
