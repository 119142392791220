import { computed, reactive } from "vue"
import { defineStore } from "pinia"
import type { IntNavigation } from "@/router"
export interface ICurrentRoute {
  title: string
  translationKey?: string
  routeName?: string
}

export const useBreadcrumbStore = defineStore("breadcrumbStore", () => {
  const state = reactive<{
    breadcrumbs: IntNavigation[]
  }>({
    breadcrumbs: []
  })

  const setPageBreadcrumbs = (payload: IntNavigation[]) => {
    state.breadcrumbs = []
    setTimeout(() => {
      state.breadcrumbs = payload
    }, 100)
  }

  const getPageBreadcrumbs = computed((): IntNavigation[] => state.breadcrumbs)

  return {
    setPageBreadcrumbs,
    getPageBreadcrumbs
  }
})
