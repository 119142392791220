import axios from "axios"
import { useAuthStore } from "@/store/modules/auth/authStore"

// const authStore = useAuthStore();

axios.interceptors.response.use((response) => {
  const authStore = useAuthStore()
  authStore.updateSessionExpiryTime()
  return response
})
