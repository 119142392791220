import { computed, ref } from "vue"
import { defineStore } from "pinia"
import Util from "@/common/util"
import { type INotification, NotificationType } from "../../notifications/INotification"

interface INotificationLink {
  text: string
  handler: () => {}
}

export const DEFAULT_DISMISS_TIMEOUT = 10000

type RawNotification = string | INotification

function getNotification(notification: RawNotification): INotification {
  const id = Util.generateUUID()
  return typeof notification === "object" ? { id, ...notification } : { id, text: notification }
}

export const useNotificationStore = defineStore("useNotificationStore", () => {
  const notifications = ref<INotification[]>([])

  const notificationList = computed(() => {
    return notifications.value
  })

  const addSuccessNotification = (notification: RawNotification) => {
    notifications.value.unshift({
      ...getNotification(notification),
      type: NotificationType.SUCCESS
    })
  }

  const addErrorNotification = (notification: RawNotification) => {
    notifications.value.unshift({ ...getNotification(notification), type: NotificationType.ERROR })
  }

  const addHintNotification = (notification: RawNotification) => {
    notifications.value.unshift({ ...getNotification(notification), type: NotificationType.HINT })
  }

  const addWarningNotification = (notification: RawNotification) => {
    notifications.value.unshift({
      ...getNotification(notification),
      type: NotificationType.WARNING
    })
  }

  const updateNotificationList = (value: INotification[]) => {
    notifications.value = value
  }

  return {
    notifications,
    addSuccessNotification,
    addErrorNotification,
    addHintNotification,
    addWarningNotification,
    updateNotificationList
  }
})
